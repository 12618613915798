<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t('LEADER_BOARD') }}
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t('HABIT') }}</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t('BEHAVIOUR') }}</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="set-report-chart"
          type="bar"
          height="230"
          :options="chartOptions"
          :series="dataSeries.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <v-select
          id="id-competition"
          v-model="competition"
          :options="competitions"
          label="c_string_swe"
          :clearable="false"
          style="font-size: x-small;"
          class="mb-1"
          @input="onChangeCompetition()"
        />
        <vue-apex-charts
          id="habit-chart"
          ref="refHabitChart"
          type="line"
          height="80"
          :options="habitChart.options"
          :series="series"
        />
        <b-button
          v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-3"
          @click="$router.push({ name: 'dashboard-overview'})"
        >
          <span>{{ $t('LEADER_BOARD') }} </span>
          <feather-icon icon="BarChart2Icon" />
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    VueApexCharts,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      competitions: [],
      competition: useJwt.getGame(),
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      dataSeries: {
        series: [
          {
            name: 'Task',
            data: [],
          },
        ],
      },
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      },
      // habit chart
      habitChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  computed: {
    ...mapGetters('game', ['getAllGames']),
  },
  async created() {
    await this.fetchCompetitions()
  },
  methods: {
    ...mapActions('game', ['fetchAllGames']),
    ...mapActions('onboarding', ['fetchSetGameResults']),
    updateAxis(data) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data,
        },
      }
    },
    async fetchCompetitions() {
      this.fetchAllGames().then(async response => {
        if (response) {
          this.competitions = response
          if (this.competitions.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.competition = this.competitions[0]
            await this.generateReport(this.competition.encrypt_id)
          }
        }
      }).catch(() => {
        this.errorMessage('There was an issue with fetching the game list')
      })
    },
    onChangeCompetition() {
      this.generateReport(this.competition.encrypt_id)
      useJwt.setGame(this.competition)
    },
    mounted() {
    },
    async generateReport(id) {
      const categoriesData = []
      this.habitDataSeries = []
      this.dataSeries.series[0].data = []
      this.series[0].data = []
      this.fetchSetGameResults(id).then(response => {
        if (response) {
          this.series[0].data = []
          response.forEach((item, index) => {
            categoriesData.push(`${index + 1}`)
            this.dataSeries.series[0].data.push(item.points)
            this.series[0].data.push(item.points)
          })
          const { chart } = this.$refs.refHabitChart
          if (chart) {
            chart.updateSeries(this.series)
          }
          this.updateAxis(categoriesData)
        }
      })
    },
  },
}
</script>
