<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- game -->
      <template #cell(game)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              :src="require(`@/assets/images/icons/${data.item.avatar_img}`)"
              alt="avatar img"
            /></b-avatar>
          <div>
            <b-link
              :to="{ name: 'game-view', params: { id: data.item.encrypt_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ strSubstring(data.item.c_string_swe,20) }}
            </b-link>
            <div class="font-small-2 text-muted">
              {{ strSubstring(data.item.visionstatement,20) }}
            </div>
          </div>
        </div>
      </template>
      <!-- category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            class="mr-1"
            :variant="data.item.avatar_color"
          >
            <feather-icon
              size="18"
              :icon="data.item.avatar_icon"
            />
          </b-avatar>
          <span>{{ data.item.category }}</span>
        </div>
      </template>
      <!-- start date -->
      <template #cell(Period)="data">
        <div class="d-flex flex-column">
          <span class="font-small-2 text-muted">{{ data.item.startdate }} to {{ data.item.enddate }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BAvatar, BImg, BLink,
} from 'bootstrap-vue'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BLink,
  },
  mixins: [mixinList],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'Game', label: 'GAME' },
        { key: 'Category', label: 'CATEGORY' },
        { key: 'Period', label: 'PERIOD' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
