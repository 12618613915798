<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        v-if="userData.userRoleId===userRole.ADMIN"
        xl="4"
        md="6"
      >
        <analytics-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <analytics-statistics :data="companyObjects" />
      </b-col>

      <b-col lg="4">
        <b-row class="match-height">
          <!-- Habit -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <analytics-habit
              :data="data.statisticsOrder"
              :habit-count="habitCount"
            />
          </b-col>
          <!--/ Behaviour -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <analytics-behaviour
              :data="data.statisticsProfit"
              :behaviour-count="behaviourCount"
            />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <analytics-app-usage
              v-if="userData.userRoleId===userRole.ADMIN"
              :data="data.earningsChart"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- Leader Board -->
      <b-col :lg="userData.userRoleId===userRole.ADMIN ? '8' : '12'">
        <analytics-leader-board :data="data.revenue" />
      </b-col>
      <!--/ Leader Board -->

      <!-- Current Games -->
      <b-col lg="8">
        <analytics-games-table :table-data="getCurrentGames" />
      </b-col>
      <!--/ Current Games -->

      <!-- Today game -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-today-game :data="competition" />
      </b-col>
    </b-row>
    <!--/ Today game -->
    <b-row
      v-if="userData.userRoleId===userRole.ADMIN"
      class="match-height"
    >
      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-browser-states />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Game Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-game-overview :data="getGameOverview" />
      </b-col>
      <!--/ Game Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import AnalyticsMedal from './AnalyticsMedal.vue'
import AnalyticsStatistics from './AnalyticsStatistics.vue'
import AnalyticsLeaderBoard from './AnalyticsLeaderBoard.vue'
import AnalyticsHabit from './AnalyticsHabit.vue'
import AnalyticsBehaviour from './AnalyticsBehaviour.vue'
import AnalyticsAppUsage from './AnalyticsAppUsage.vue'
import AnalyticsGamesTable from './AnalyticsGamesTable.vue'
import AnalyticsTodayGame from './AnalyticsTodayGame.vue'
import AnalyticsBrowserStates from './AnalyticsBrowserStates.vue'
import AnalyticsGameOverview from './AnalyticsGameOverview.vue'
import AnalyticsTransactions from './AnalyticsTransactions.vue'
import { countingObject } from '@/constants/utils'
import constants from '@/constants/static.json'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsMedal,
    AnalyticsStatistics,
    AnalyticsLeaderBoard,
    AnalyticsHabit,
    AnalyticsBehaviour,
    AnalyticsAppUsage,
    AnalyticsGamesTable,
    AnalyticsTodayGame,
    AnalyticsBrowserStates,
    AnalyticsGameOverview,
    AnalyticsTransactions,
  },
  data() {
    return {
      data: {},
      competition: {
        usersCount: 0,
        groupCount: 0,
        users: [],
      },
      userRole: constants.USER_ROLE,
      companyObjects: [],
      behaviourCount: '0',
      habitCount: '0',
      userData: {},
    }
  },
  computed: {
    ...mapGetters('dashboard', ['getCompanyOverview', 'getCurrentGames', 'getGameOverview']),
    ...mapGetters('onboarding', ['getAllGame']),
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        this.userData = useJwt.getUser()
        this.data.congratulations.name = this.userData.fullName.split(' ')[0] || this.userData.username
      })
    this.fetchOverview()
    this.fetchGames()
    this.fetchGamesOverview()
  },
  methods: {
    ...mapActions('dashboard', ['fetchCompanyOverview', 'fetchCurrentGames', 'fetchGamesOverview']),
    ...mapActions('onboarding', ['fetchAllGame']),
    fetchOverview() {
      this.fetchCompanyOverview().then(response => {
        if (response) {
          const data = response
          this.companyObjects = data.filter(item => item.subtitle !== 'SubTasks' && item.subtitle !== 'Tasks')
          this.behaviourCount = data.filter(item => item.subtitle === 'SubTasks')[0].title
          this.habitCount = data.filter(item => item.subtitle === 'Tasks')[0].title
        }
      })
    },
    fetchGames() {
      this.fetchCurrentGames().then(response => {
        if (response) {
          this.fetchAllGame(response[0].encrypt_id).then(game => {
            if (game.data) {
              const { data } = game
              this.competition = data
              const resuts = countingObject(data.groups)
              this.competition.usersCount = resuts.usersCount
              this.competition.groupCount = resuts.groupCount
              this.competition.users = resuts.users
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
